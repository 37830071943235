import moment from "moment";

export const formatDataset = (data, title, color, labelFormatter = ({label}) => label) => ({
    labels: data.map(labelFormatter),
    datasets: [
        {
            label: title,
            borderColor: color,
            pointBackgroundColor: color,
            borderWidth: 1,
            backgroundColor: color + '10',
            data: data.map(({count}) => count)
        }
    ]
});

export const formatMonthDataset = (data, title, color) => formatDataset(
    data,
    title,
    color,
    ({date}) => moment(date, 'YYYY-MM').format('MMMM YYYY')
);

export default {formatDataset, formatMonthDataset};