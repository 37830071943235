<template>
    <b-row>
        <b-col xl="6">
            <card-chart title="Nombre de connexions" :data="seriesConnexions"/>
        </b-col>
        <b-col xl="6">
            <card-chart title="Nombre d'emprunts valides" :data="seriesEmprunts"/>
        </b-col>
        <b-col xl="6">
            <card-chart title="Nombre de réservations du local valides" :data="seriesReservations"/>
        </b-col>
        <b-col xl="6">
            <card-chart title="Nombre d'événements valides" :data="seriesEvenements"/>
        </b-col>
        <b-col xl="6">
            <card-chart type="Doughnut" title="Profil des utilisateurs" :data="seriesUserTypes"/>
        </b-col>
    </b-row>
</template>

<script>
    import {apiPath}   from '@/util/http';
    import alert       from '@/util/alert';
    import stats       from '@/util/stats';
    import colors      from '@/util/colors';
    import {roleInfos} from '@/util/user';

    const CardChart = () => import('@/components/stats/CardChart');

    export default {
        name: "AdminStats",
        components: {CardChart},
        data: () => ({
            seriesConnexions: {},
            seriesEmprunts: {},
            seriesReservations: {},
            seriesEvenements: {},
            seriesUserTypes: {},
            colors
        }),
        methods: {
            loadConnexions() {
                return this.axios.get(apiPath('stats_count_connexions_month'))
                    .then(response => this.seriesConnexions = stats.formatMonthDataset(
                        response.data,
                        'Connexions',
                        colors.utilisateurs.background
                    ))
                    .catch(() => this.$toaster.error('Impossible de récupérer les statistiques des connexions'));
            },
            loadEmprunts() {
                return this.axios.get(apiPath('stats_count_emprunts_month'))
                    .then(response => this.seriesEmprunts = stats.formatMonthDataset(
                        response.data,
                        'Emprunts',
                        colors.jeux.background
                    ))
                    .catch(() => this.$toaster.error('Impossible de récupérer les statistiques des emprunts'));
            },
            loadReservations() {
                return this.axios.get(apiPath('stats_count_reservations_month'))
                    .then(response => this.seriesReservations = stats.formatMonthDataset(
                        response.data,
                        'Réservations du local',
                        colors.local.background
                    ))
                    .catch(() => this.$toaster.error('Impossible de récupérer les statistiques des réservations du local'));
            },
            loadEvenements() {
                return this.axios.get(apiPath('stats_count_evenements_month'))
                    .then(response => this.seriesEvenements = stats.formatMonthDataset(
                        response.data,
                        'Événements',
                        colors.evenements.background
                    ))
                    .catch(() => this.$toaster.error('Impossible de récupérer les statistiques des événements'));
            },
            loadUserTypes() {
                return this.axios.get(apiPath('admin_valid_users_quickview'))
                    .then(response => {
                            this.seriesUserTypes = {
                                labels: [],
                                datasets: [
                                    {
                                        label: "Types d'utilisateurs",
                                        borderWidth: 1,
                                        backgroundColor: [],
                                        data: []
                                    }
                                ]
                            };

                            for (let user of response.data) {
                                let role  = roleInfos(user).name;
                                let found = false;
                                for (let key in this.seriesUserTypes.labels) {
                                    if (this.seriesUserTypes.labels[key] === role) {
                                        this.seriesUserTypes.datasets[0].data[key]++;
                                        found = true;
                                        break;
                                    }
                                }
                                if (!found) {
                                    let r = Math.floor(Math.random() * 255);
                                    let g = Math.floor(Math.random() * 255);
                                    let b = Math.floor(Math.random() * 255);
                                    let c = 'rgb(' + r + ', ' + g + ', ' + b + ')';
                                    this.seriesUserTypes.labels.push(role);
                                    this.seriesUserTypes.datasets[0].data.push(1);
                                    this.seriesUserTypes.datasets[0].backgroundColor.push(c);
                                }
                            }
                        }
                    )
                    .catch(() => this.$toaster.error("Impossible de récupérer les statistiques des types d'utilisateurs"));
            },
            loadData() {
                alert.loading();
                this.loadConnexions()
                    .then(() => this.loadEmprunts())
                    .then(() => this.loadReservations())
                    .then(() => this.loadEvenements())
                    .then(() => this.loadUserTypes())
                    .finally(alert.stopLoading);
            }
        },
        mounted() {
            this.loadData();
        }
    }
</script>

<style scoped>
    .chartContainer, .chartContainer > div {
        max-height: 50vh;
    }
</style>